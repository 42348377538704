import logo from './logo.svg';
import './App.css';
import { Component } from 'react';
import ProjectWrap from './Component/ProjectWrap';

export default class App extends Component {
  sendAudio = () => {
    const e = document.body
      , t = (document.documentElement,
        document.querySelector(".zee__instruction"));
    function i(e) {
      console.log(e)
    };
    function r(e) {
      "transform" === e.propertyName && this.classList.remove("playing")
    };
    const s = document.querySelectorAll(".key");
    s.forEach(e => e.addEventListener("transitionend", r));
    s.forEach(e => e.addEventListener("mouseover", function () {
      const t = this.getAttribute("data-key")
        , i = document.querySelector(`audio[data-key="${t}"]`);
      i && i.play();
      i && e.classList.add("playing");
    }));
    window.addEventListener("keydown", function (e) {
      t.classList.add("hidden");
      const i = document.querySelector(`audio[data-key="${e.keyCode}"]`);
      const n = document.querySelector(`.key[data-key="${e.keyCode}"]`);
      i && (i.currentTime = 0);
      i && i.play();
      i && n.classList.add("playing");
    });
  }

  componentDidMount() {
    this.sendAudio();
  }
  render() {
    return (
      <div className="App">
        <div className='header-menu'>
        <p style={{maxWidth: "40%"}}>Alexandre Jouard </p>
          <div className='menu'>
            <div className='menu-element'>work</div>
            <div className='menu-element'>about</div>
          </div>
        </div>
        <section className="hero container">
          <figure style={{ backgroundImage: "url('mainbg.png')" }}></figure>
          <div className="hero__wrapper">
            <section id="zee">
              <div>
                <span data-key="49" className="key"></span>
                <span data-key="50" className="key z"></span>
                <span data-key="51" className="key"></span>
              </div>
              <div>
                <span data-key="52" className="key"></span>
                <span data-key="53" className="key"></span>
                <span data-key="54" className="key"></span>
              </div>
              <div>
                <span data-key="55" className="key"></span>
                <span data-key="56" className="key"></span>
                <span data-key="57" className="key"></span>
              </div>
              <p className="zee__instruction">press 1 to 9</p>


              <audio data-key="49" src="https://www.tommyzee.co/wp-content/uploads/2018/11/1.wav"></audio>
              <audio data-key="50" src="https://www.tommyzee.co/wp-content/uploads/2018/11/2.wav"></audio>
              <audio data-key="51" src="https://www.tommyzee.co/wp-content/uploads/2018/11/3.wav"></audio>
              <audio data-key="52" src="https://www.tommyzee.co/wp-content/uploads/2018/11/4.wav"></audio>
              <audio data-key="53" src="https://www.tommyzee.co/wp-content/uploads/2018/11/5.wav"></audio>
              <audio data-key="54" src="https://www.tommyzee.co/wp-content/uploads/2018/11/6.wav"></audio>
              <audio data-key="55" src="https://www.tommyzee.co/wp-content/uploads/2018/11/7.wav"></audio>
              <audio data-key="56" src="https://www.tommyzee.co/wp-content/uploads/2018/11/8.wav"></audio>
              <audio data-key="57" src="https://www.tommyzee.co/wp-content/uploads/2018/11/9.wav"></audio>

            </section>
          </div>
        </section>
        <ProjectWrap />
      </div>
    )
  }
}
