import { Component } from "react";
import "./ProjectWrap.css"

export default class ProjectWrap extends Component {
    setVideo = () => {
        const c = document.querySelectorAll(".project")
            , d = document.querySelector(".video-modal")
            , h = document.querySelectorAll(".close")
            , p = document.querySelector(".video-modal__description")
            , f = document.querySelector("#video-element");
        c.forEach(function (e) {
            e.addEventListener("click", function () {
                const path = this.getAttribute("path");
                const url = path;
                p.innerHTML = this.querySelector(".project__description").innerHTML;
                var frame = f.getElementsByTagName("iframe")[0];
                frame.setAttribute("src", url);
                //const frame = <iframe src="+url +" width='800' height='450' frameborder='0' allow='autoplay; fullscreen; picture-in-picture' title='Vans - Style Matters' data-ready='true'></iframe>;
                //f.appendChild(frame);
                d.classList.add("visible");
                h.forEach(function (e) {
                    e.addEventListener("click", function () {
                        frame.setAttribute("src", "");
                        d.classList.remove("visible");
                    })
                })
            })
        });
    }

    componentDidMount() {
        this.setVideo();
    }

    render() {
        return (
            <section className="project-list">
                <div className="video-modal">
                    <div className="overlay close"></div>
                    <div id="video-element">
                        <div className="close-button close"></div>
                        <div id="video-expander" style={{ paddingBottom: "56.25%" }}></div>
                        <iframe src="" width='800' height='450' frameborder='0' allow='autoplay; fullscreen; picture-in-picture' title='Vans - Style Matters' data-ready='true'></iframe>
                    </div>
                    <div className="video-modal__description container">
                        <div className="html-before-content"></div>
                        <div className="entry">
                            <p>To celebrate International Women’s Month – we were asked to compose an original score for this short film by Vans&nbsp;– following the inspiring story of remarkable Japanese female skateboarders – Cocona Hiraki &amp; Mami Tezuka.</p>
                        </div>
                        <div className="html-after-content"></div>
                        toto                  </div>
                </div>


                <section className="project container" path="https://player.vimeo.com/video/860245401?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479">
                    <div className="project__wrapper">
                        <div style={{ backgroundImage: "url('creedbg.png')" }} className="project__content">
                            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                <g>
                                </g>
                                <path d="M152.443 136.417l207.114 119.573-207.114 119.593z" fill="#fff"></path>
                            </svg>
                            <div className="project__title">
                                <article className="text-wrapper">
                                    <h2>ACTION</h2>
                                    <p>
                                        dramatic hybrid orchestration</p>

                                </article>
                                <div className="solid"></div>
                            </div>
                            <article className="project__description">
                                <div className="html-before-content"></div>
                                <div className="entry">
                                    <p>A dramatic journey into the warrior mindset and the mentality of overcoming challenges and facing adversity. The music revolves around piano, synphonic instruments and synthesizers. The sonic palette suits the dramatic visuals and adapt on the intensity of each scene.</p>
                                </div>
                                <div className="html-after-content"></div>
                                </article>
                        </div>
                    </div>
                </section>

                <section className="project container" path="https://player.vimeo.com/video/860246677?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479">
                    <div className="project__wrapper">
                        <div style={{ backgroundImage: "url('pingouinsbg.png')" }} className="project__content">
                            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                <g>
                                </g>
                                <path d="M152.443 136.417l207.114 119.573-207.114 119.593z" fill="#fff"></path>
                            </svg>
                            <div className="project__title">
                                <article className="text-wrapper">
                                    <h2>DOCUMENTARY</h2>
                                    <p>
                                        soft orchestral</p>

                                </article>
                                <div className="solid"></div>
                            </div>
                            <article className="project__description">
                                <div className="html-before-content"></div>
                                <div className="entry">
                                    <p>A short documentary showing the survival of penguins in Antarctica. The sonic elements fit the icy atmosphere, the cold beauty of Antarctica and the playfulness of penguins fighting every day to survive in this hostile environment.</p>
                                </div>
                                <div className="html-after-content"></div>
                                                 </article>
                        </div>
                    </div>
                </section>

            </section>
        )
    }
}